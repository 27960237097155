<template>
   <div class="d-flex align-self-center">
      <AmcCard Card class="rounded-pill p-1">
         <button
            @click="$emit('filterChanged', 'fire', parseInt(filters.fire) === 1 ? 0 : 1)"
            :class="{ 'text-primary bg-light-primary': parseInt(filters.fire) === 1 }"
            class="state-btn btn me-3"
         >
            {{ $t('buttons.Alarm') }}
            <AmcBadge v-if="!noCount" Alarm>{{ fire }}</AmcBadge>
         </button>
         <button
            @click="$emit('filterChanged', 'fault', parseInt(filters.fault) === 1 ? 0 : 1)"
            :class="{ 'text-primary bg-light-primary': parseInt(filters.fault) === 1 }"
            class="state-btn btn me-3"
         >
            {{ $t('buttons.Arıza') }}
            <AmcBadge v-if="!noCount" Fault>{{ fault }}</AmcBadge>
         </button>

         <button
            @click="$emit('filterChanged', 'info', parseInt(filters.info) === 1 ? 0 : 1)"
            :class="{ 'text-primary bg-light-primary': parseInt(filters.info) === 1 }"
            class="state-btn btn me-3"
         >
            {{ $t('buttons.Bilgi') }}
            <AmcBadge v-if="!noCount" Info>{{ info }}</AmcBadge>
         </button>
         <button
            @click="$emit('filterChanged', 'is_connected', parseInt(filters.is_connected) === 1 ? 0 : 1)"
            :class="{ 'text-primary bg-light-primary': parseInt(filters.is_connected) === 1 }"
            class="state-btn btn me-3"
         >
            {{ $t('buttons.Çevrimiçi') }}
            <AmcBadge v-if="!noCount" Online>{{ online }}</AmcBadge>
         </button>
         <button
            @click="$emit('filterChanged', 'is_disconnected', parseInt(filters.is_disconnected) === 1 ? 0 : 1)"
            :class="{ 'text-primary bg-light-primary': parseInt(filters.is_disconnected) === 1 }"
            class="state-btn btn"
         >
            {{ $t('buttons.Çevrimdışı') }}
            <AmcBadge v-if="!noCount" Offline>{{ offline }}</AmcBadge>
         </button>
      </AmcCard>
   </div>
</template>

<script>
export default {
   name: 'AmcStateButton',
   props: {
      fire: {
         required: false,
         default: 0,
      },
      online: {
         required: false,
         default: 0,
      },
      offline: {
         required: false,
         default: 0,
      },
      fault: {
         required: false,
         default: 0,
      },
      info: {
         required: false,
         default: 0,
      },
      filters: {
         required: false,
      },
      noCount: {
         required: false,
         default: false,
      },
   },
};
</script>

<style scoped lang="scss">
.state-btn {
   border-radius: 100px;
   background-color: transparent;
   font-weight: 500;
   color: #919699;
   padding-right: 25px;
   padding-left: 25px;
   &:hover {
      background-color: #e5efff;
      color: #0a65ff !important;
   }

   & .select {
      background-color: #e5efff;
      color: #0a65ff !important;
   }
}
</style>
